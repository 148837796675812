<template>
	<div>
		<product-filters :facets="facets" @facet-clicked="updateFacets" @facets-reset="resetFacets" :rules="rules" @clear-vehicle="clearVehicle" />

		<!-- Pagination -->
		<!-- <pagination :pagination="pagination" @page-change="setPage" /> -->

		<!-- Sorting -->
		<div class="d-flex justify-content-center w-100 justify-content-md-end">
			<div style="width: 250px;">
				<label class="form-label">Sort by:</label>
				<select class="form-select" v-model="sort">
					<option v-for="(value, key) in sortOptions" :key="key" :value="key">{{ value }}</option>
				</select>
			</div>

		</div>
		<div class="row pt-3 mx-n2 row-cols-xl-5 row-cols-lg-4 row-cols-md-3 row-cols-1 " v-show="loading">
			<div class="col px-2 mb-4" v-for="i in 24" :key="i">
				<!-- Loading placeholders -->
				<div class="card product-card shadow-sm" aria-hidden="true">
					<div class="position-relative placeholder-wave">
						<div class="card-img-top placeholder ratio ratio-16x9"></div>
						<i
						   class="ci-image position-absolute top-50 start-50 translate-middle fs-1 text-muted opacity-50"></i>
					</div>
					<div class="card-body py-2">
						<span class="product-meta d-block fs-xs pb-1 placeholder placeholder-sm"></span>
						<h2 class="card-title placeholder-glow">
							<span class="placeholder col-6"></span>
						</h2>

						<a tabindex="-1" class="bg-primary disabled placeholder col-8 placeholder-wave me-4"></a>
					</div>
				</div>
			</div>

		</div>

		<div class="row pt-3 mx-n2 row-cols-xl-5 row-cols-lg-4 row-cols-md-3 row-cols-1 " v-show="!loading">
			<!-- Errors -->
			<div v-if="errorMessage" class="col-12 d-flex justify-content-center">
				<div class="alert alert-danger " role="alert">
					{{ errorMessage }}
				</div>
			</div>

			<div class="col px-2 mb-4" v-for="product in products" :key="product.products_id">
				<product-card :product="product" :isWholesale="isWholesale" />
			</div>
			<div v-if="products.length == 0" class="w-100 d-flex justify-content-center">
				<div class="text-center w-100">
					<h1>No products found</h1>
					<p class="text-muted">Try another search term, or removing a filter.</p>
					<button class="btn btn-primary" @click="resetFacets">Reset Filters</button>
				</div>
			</div>
		</div>
		<!-- Pagination -->
		<pagination :pagination="pagination" @page-change="setPage" />
	</div>
</template>

<script>
import ProductCard from './ProductCard.vue';
import ProductFilters from './ProductFilters.vue';
import Pagination from '../common/Pagination.vue';
export default {
	name: 'ProductList',
	components: {
		ProductCard,
		ProductFilters,
		Pagination
	},
	data() {
		return {
			products: [],
			selectedFilters: {
			},
			productsPerPage: 24,
			sort: 'r',
			sortOptions: {
				'rel': 'Relevance',
				'r': 'Recommended',
				'1a': 'Model: A to Z',
				'1d': 'Model: Z to A',
				'3a': 'Name: A to Z',
				'3d': 'Name: Z to A',
				'4a': 'Price: Low to High',
				'4d': 'Price: High to Low',
				'5a': 'Oldest Products',
				'5d': 'Newest Products'
			},
			loading: true,
			page: 1,
			categories_id: 0,
			pagination: {},
			isWholesale: false,
			facets: [],
			preloadData: true,
			isPreloaded: false,
			errorMessage: '',
			isTireCategory: false,
			isManufacturerUrl: false,
			isCategoryUrl: false,
			rules: {
				// Rules for facets
			}
		}
	},
	created() {
		if (window.vrdata.categories_id) {
			this.selectedFilters.categories_id = window.vrdata.categories_id;
		}
		if (window.vrdata.manufacturers_id) {
			this.selectedFilters.manufacturers_id = window.vrdata.manufacturers_id;
		}
		// Vehicle
		if (window.vrdata.vehicle.model_id != 0) {
			this.selectedFilters.class_9 = window.vrdata.vehicle.model_id;
		}

		// Check if the page is a manufacturer page
		let url = window.location.href;


		if (url.includes('-m-')) {
			this.isManufacturerUrl = true;
		}
		if (url.includes('-c-')) {
			this.isCategoryUrl = true;
		}

		// Get query params from url
		const urlParams = new URLSearchParams(window.location.search);
		// Get the page
		if (urlParams.has('page')) {
			this.page = urlParams.get('page');
		}
		// Get the facets
		for (let [key, value] of urlParams) {
			if (key != 'page') {
				this.selectedFilters[key] = value;
			}
		}
		if (this.preloadData) {
			let data = window.VR_PRELOAD;
			if (data != null) {
				this.isPreloaded = true;
				this.products = data.products;
				this.loading = false;
				this.pagination = data.pagination;
				this.isWholesale = data.is_wholesale;
				this.facets = data.facets;
				this.rules.maxPrice = data.max_price;
				this.isTireCategory = data.is_tire_category;
				this.sort = data.sort;
				if (data.sort != 'rel') {
					// Remove relevance from sort
					delete this.sortOptions['rel'];
				}
			} else {
				this.getProducts();
			}

		} else {
			this.getProducts();

		}
	},
	methods: {
		setPage(page) {
			this.page = page;
			this.getProducts();
		},
		clearVehicle() {
			// Delete the keys
			delete this.selectedFilters.class_9;
			delete this.selectedFilters.class_8;
			delete this.selectedFilters.class_year;

			this.selectedFilters.universal = 1;

			// Clear window data
			window.vrdata.vehicle = {
				model_id: 0,
				make_id: 0,
				year: 0
			};
			this.setHeroTitle("Products");
			this.updateUrl();
			this.getProducts();
		},

		setHeroTitle(title, breadCrumb = "") {
			if (breadCrumb === "") {
				breadCrumb = title;
			}

			let titleEl = document.getElementById('hero-title');
			if (titleEl) {
				titleEl.innerHTML = title;
			}


			let breadcrumbEl = document.querySelector('.hero .breadcrumb .breadcrumb-item.active');
			if (breadcrumbEl) {
				breadcrumbEl.innerHTML = breadCrumb;
			}
		},
		updateFacets(facet, value) {
			console.log("updateFacets", facet, value);
			try {
				if (value == '') {
					// Remove the facet
					delete this.selectedFilters[facet];
				} else {
					this.selectedFilters[facet] = value;
				}
				this.page = 1;
				this.getProducts();
			} catch (error) {
				console.error(error);

			}

		},
		resetFacets() {
			this.selectedFilters = {};
			if (window.vrdata.categories_id) {
				this.selectedFilters.categories_id = window.vrdata.categories_id;
			}
			if (window.vrdata.manufacturers_id) {
				this.selectedFilters.manufacturers_id = window.vrdata.manufacturers_id;
			}
			// Vehicle
			if (window.vrdata.vehicle.model_id != 0) {
				this.selectedFilters.class_9 = window.vrdata.vehicle.model_id;
			}
			this.page = 1;
			this.getProducts();
		},
		updateUrl() {
			// Get the url params
			let urlParams = new URLSearchParams();
			for (let [key, value] of Object.entries(this.selectedFilters)) {
				if (key == 'manufacturers_id' && this.isManufacturerUrl) {
					continue
				}
				if (key == 'categories_id' && this.isCategoryUrl) {
					continue
				}
				console.log(key, value);
				if (key == 'universal' ) {
					if (value == true) {
						urlParams.set('universal', 1);
					}else{
						urlParams.set('universal', 0);
					}
					continue;		
				}
				
				if (value !== '') {

					urlParams.set(key, value);
				}
			}
			// Set the page
			if (this.page != 1) {
				urlParams.set('page', this.page);
			}
			// Update the url
			window.history.replaceState({}, '', `${location.pathname}?${urlParams}`);
		},

		getProducts() {
			this.loading = true;
			// Set the url params
			fetch(`/products_ajax.php?` + new URLSearchParams({
				page: this.page,
				sort: this.sort,
				is_tire_category: this.isTireCategory,
				...this.selectedFilters
			}))
				.then(response => response.json())
				.then(data => {
					this.loading = false;
					this.products = data.products;
					this.pagination = data.pagination;
					this.isWholesale = data.is_wholesale;
					this.facets = data.facets;
					this.rules.maxPrice = data.max_price ? data.max_price : 100;
					this.updateUrl();
				})
				.catch(error => {
					console.error(error);
					this.errorMessage = "There was an error loading the products. Please try again later."
					this.loading = false;
				});
		},
	},
	watch: {
		sort(newValue, oldValue) {
			this.page = 1;
			this.getProducts();
		},
		page(newValue, oldValue) {
			// this.getProducts();
			// Scroll to top element
			// Get the element:
			// let el = document.getElementById('product-list');
			// // Get the top position of the element
			// let topPos = el.offsetTop;
			// // Scroll to that position
			// window.scrollTo({
			// 	top: topPos - 100,
			// 	behavior: 'smooth'
			// });

		}
	},
}
</script>

